import React from "react";
import { useStyles } from "./styles";

const FormField = React.forwardRef(({ label, type }, ref) => {
    const classes = useStyles();
    return (
        <div>
            <input
                ref={ref}
                placeholder={label}
                type={type}
                className={classes.inputStyle}
            />
        </div>
    );
});

export default FormField;
