import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    errorMessage: {
        width: "100%",
        padding: 10,
        marginBottom: 10,
        borderRadius: 4,
        backgroundColor: "orangered",
        textAlign: "center",
        color: "white",
        textTransform: "capitalize",
    },
}));
