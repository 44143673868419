import React from 'react';
import { useStyles } from './styles';

const ChooseSeveral = ({ selected, setSelected, question }) => {
  const classes = useStyles();

  const chooseAnswer = (choosen) => {
    const localArr = [...selected];
    const index = localArr.findIndex((sel) => sel.id === choosen.id);
    if (index === -1) {
      localArr.push(choosen);
    } else {
      localArr.splice(index, 1);
    }
    setSelected(localArr);
  };

  return (
    <>
      <h2 className={classes.questionName}>
        {question.question} <em>(choose several)</em>
      </h2>
      <div className={classes.options}>
        {question.variants &&
          question.variants.map((item) => {
            const index = selected.findIndex((sel) => sel.id === item.id);
            return (
              <button
                className={`${classes.singleOption} ${
                    index !== -1 && classes.select
                }`}
                key={item.id}
                onClick={() => chooseAnswer(item)}
              >
                {item.isCode ? (
                  <pre className={classes.codeBlock}>{item.label}</pre>
                ) : (
                  item.label
                )}
              </button>
            );
          })}
      </div>
    </>
  );
};

export default ChooseSeveral;
