import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formStyle: {
    margin: 'auto',
    padding: 10,
    borderRadius: 5,
    width: 320,
    display: 'block',
  },
  submitStyle: {
    margin: '10px 0 0 0',
    padding: 10,
    border: 'none',
    borderRadius: 3,
    background: '#32cd32',
    width: '100%',
    fontSize: 20,
    color: 'white',
    display: 'block',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#32C09F',
    },
  },
  loginError: {
    fontSize: 15,
    marginTop: 10,
    background: '#8B0000',
    color: '#fff',
    textAlign: 'center',
    padding: 10,
  },
}));
