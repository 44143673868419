import React from 'react';
import Form from '../../components/Form';
import { useStyles } from './styles';

const Home = ({ queryParam }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {queryParam.name && queryParam.value && (
        <Form goTo='test' />
      )}
      {!queryParam.name && !queryParam.value && (
        <Form goTo='my-results' />
      )}
      {queryParam.name && !queryParam.value && (
        <h1 className={classes.errorMessage}>No test parameters specified. <br/>
        Please try again or contact technical support.</h1>
      )}
    </div>
  );
};

export default Home;
