import { ApplicationSettings } from '../settings/applicationSettings';
import axios from 'axios';
//import store from "../store";

// axios.interceptors.request.use(
//     (config) => {
//         const state = store.getState();
//         let token = state?.token;
//         if (token) {
//             config.headers["Authorization"] = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

const { api } = ApplicationSettings;

const API = {
  login: (data) => {
    return axios({
      method: 'post',
      url: `${api}/check-login`,
      data,
    });
  },
  logSession: () => {
    return axios({
      method: 'get',
      url: `${api}/session`,
    });
  },
  getTaskByBranch: (branchId, testType) => {
    return axios({
      method: 'get',
      url: `${api}/get-test/branch/${branchId}/${testType}`,
    });
  },
  getTaskByCourse: (courseId, testType) => {
    return axios({
      method: 'get',
      url: `${api}/get-test/course/${courseId}/${testType}`,
    });
  },
  getTask: (taskId) => {
    return axios({
      method: 'get',
      url: `${api}/get-task/${taskId}`,
    });
  },
  checkTask: (taskId, data) => {
    return axios({
      method: 'post',
      url: `${api}/check-task/${taskId}`,
      data,
    });
  },
  finishTest: () => {
    return axios({
      method: 'post',
      url: `${api}/finish-test`,
    });
  },
  getMyResults: () => {
    return axios({
      method: 'get',
      url: `${api}/results`,
    });
  },
};

export default API;
