import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import API from '../../services/api.service';

const MyResults = () => {
  const [myResults, setMyResults] = useState(null);
  
  useEffect(async () => {
    try {
      const { data } = await API.getMyResults();
      setMyResults(data);
    } catch (e) {
      console.log('Error:', e);
    }
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.myResults}>
      {myResults ? (
        <div style={{ textAlign: 'center' }}>
          <h1>My Results</h1>
          <table>
            <tr>
              <th>Name</th>
              <th>Correct Count</th>
              <th>Wrong Count</th>
              <th>%</th>
              <th>Time start</th>
              <th>Time end</th>
            </tr>
            {myResults.map((item) => (
              <tr key={item.id}>
                <td>{item.branch_id || item.course_id}</td>
                <td>{item.correct_count}</td>
                <td>{item.wrong_count}</td>
                <td>{item.percentage}%</td>
                <td>{new Date(Date.parse(`${item.time_start} GMT-0300 GMT+0000`)).toLocaleString()}</td>
                <td>{new Date(Date.parse(`${item.time_end} GMT-0300 GMT+0000`)).toLocaleString()}</td>
              </tr>
            ))}
          </table>
        </div>
      ) : (
        <CircularProgress
          style={{ margin: 100, alignSelf: 'center' }}
          color="inherit"
          size={50}
          thickness={1}
        />
      )}
    </div>
  );
};

export default MyResults;
