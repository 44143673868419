import { useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useStyles } from './styles';
import API from '../../services/api.service';
import ErrorMessage from '../ErrorMessage';
import ChooseOne from './components/ChooseOne';
import ChooseSeveral from './components/ChooseSeveral';
import MatchItems from './components/MatchItems';
import Alert from '@material-ui/lab/Alert';

const Question = ({
  currQues,
  setCurrQues,
  question,
  setQuestion,
  totalTasks,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [queryError, setQueryError] = useState('');
  const history = useHistory();

  const handleNext = async () => {
    if (currQues >= totalTasks - 1) {
      history.push('/result');
    } else {
      try {
        setQueryError('');
        await API.checkTask(currQues, selected);
      } catch(e) {
        setQueryError(e.data.message);
      }
      setCurrQues(currQues + 1);
      setQuestion(null);
      setSelected();
    }
  };

  const renderQuestion = () => {
    switch (question.type) {
      case 'choose_one':
        return (
          <ChooseOne
            selected={selected}
            setSelected={setSelected}
            question={question}
          />
        );
      case 'choose_several':
        return (
          <ChooseSeveral
            selected={selected}
            setSelected={setSelected}
            question={question}
          />
        );
      case 'match_items':
        return (
          <MatchItems
            setSelected={setSelected}
            question={question}
          />
        );
      default:
        return <ErrorMessage>Something went wrong.</ErrorMessage>;
    }
  };

  return (
    <div className={classes.question}>
      <h1 className={classes.questionCounter}>
        Question {currQues + 1}/{totalTasks} :
      </h1>
      <div className={classes.singleQuestion}>
        {renderQuestion()}
        <div className={classes.controls}>
        {queryError && <Alert severity="error">{queryError}</Alert>}
          <Button
            variant="contained"
            size="large"
            className={classes.buttonControl}
            onClick={handleNext}
          >
            {currQues >= totalTasks - 1 ? 'Submit' : 'Next Question'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Question;
