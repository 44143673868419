import React from 'react';
import { useStyles } from './styles';

const ChooseOne = ({ selected, setSelected, question }) => {
  const classes = useStyles();

  const chooseAnswer = (choosen) => {
    setSelected([choosen]);
  }

  return (
    <>
      <h2 className={classes.questionName}>{question.question} <em>(choose one)</em></h2>
      <div className={classes.options}>
        {question.variants &&
          question.variants.map((item) => (
            <button
              className={`${classes.singleOption} ${
                selected[0]?.label === item.label && classes.select
              }`}
              key={item.id}
              onClick={() => chooseAnswer(item)}
            >
              {item.isCode ? (
                <pre className={classes.codeBlock}>{item.label}</pre>
              ) : (
                item.label
              )}
            </button>
          ))}
      </div>
    </>
  );
};

export default ChooseOne;
