import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  result: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 100,
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
  },
  resultPassedHeader: {
    fontSize: '50px',
    color: '#00df76',
    textAlign: 'center',
  },
  resultFailedHeader: {
    fontSize: '50px',
    color: '#F44336',
    textAlign: 'center',
  },
  score: {
    marginTop: 20,
  },
  mark: {
    textDecoration: 'underline',
  },
  topicsToLearn: {
    fontWeight: 'bold',
    fontSize: 40,
    textAlign: 'left',
  },
  topicsLink: {
    color: '#000',
    fontSize: 21,
  },
  topicsData: {
    width: '100%',
    maxWidth: '950px',
    minHeight: '100px',
    margin: '0 auto',
  },
  topicsItem: {
    border: '3px solid #ccc',
    borderRadius: 10,
    margin: 10,
    padding: 10,
    textAlign: 'justify',
  },
  topicsItemHeader: {
    fontWeight: 'bold',
    display: 'inline',
  },
  buttonsBlock: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  buttonItem: {
    color: '#fff',
    marginLeft: 20,
    '&:hover': {
      opacity: 0.8,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 10,
    },
  },
}));
