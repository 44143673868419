import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Test from './pages/Test';
import Result from './pages/Result';
import MyResults from './pages/MyResults';
import methods from './services/methods';

const App = () => {
  const [queryParam, setQueryParam] = useState({
    name: methods.getQueryParamName(),
    value: methods.getQueryParamValue('branchId') || methods.getQueryParamValue('courseId'),
    isShort: methods.getQueryParamShort() || '',
  });
  

  return (
    <Router>
      <Header />
      <Switch>
        <Route
          path="/"
          exact={true}
          component={() => <Home queryParam={queryParam} />}
        />
        <Route
          path="/test"
          component={() => <Test queryParam={queryParam} />}
        />
        <Route path="/result" component={Result} />
        <Route path="/my-results" component={MyResults} />
      </Switch>
    </Router>
  );
};

export default App;
