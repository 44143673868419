import { CircularProgress, Button } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useEffect, useState } from 'react';
import Question from '../../components/Question';
import { useStyles } from './styles';
import API from '../../services/api.service';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import { branchIdValue } from '../../settings/applicationSettings';

const Test = ({ queryParam }) => {
  const classes = useStyles();
  const [currQues, setCurrQues] = useState(0);
  const [question, setQuestion] = useState(null);
  const [totalTasks, setTotalTasks] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();

  const checkIsShort = () => 
    queryParam.isShort ? 'short' : 'default';

  useEffect(async () => {
    try {
      setError('');
      setIsLoading(true);
      const { data } =
        queryParam.name === branchIdValue
          ? await API.getTaskByBranch(queryParam.value, checkIsShort())
          : await API.getTaskByCourse(queryParam.value, checkIsShort());
      setTotalTasks(data?.totalTasks);
      if (data?.success) {
        const questionData = await API.getTask(currQues);
        setQuestion(questionData.data);
      } else {
        console.log('something went wrong');
      }
      setIsLoading(false);
    } catch (e) {
      setError(e?.data?.message);
      setIsLoading(false);
    }
  }, []);

  const quitTest = async () => {
    try {
      setError('');
      await API.finishTest();
      history.push('my-results');
    } catch (e) {
      setError(e?.data?.message);
    }
  };

  useEffect(async () => {
    if (currQues >= 1) {
      setQuestion(null);
      try {
        setError('');
        setIsLoading(true);
        const questionData = await API.getTask(currQues);
        setQuestion(questionData.data);
        setIsLoading(false);
      } catch (e) {
        setError(e?.data?.message);
        setIsLoading(true);
      }
    }
  }, [currQues]);

  return (
    <div className={classes.quiz}>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading && (
        <CircularProgress
          style={{ margin: 100 }}
          color="inherit"
          size={50}
          thickness={1}
        />
      )}

      {!isLoading && question && (
        <>
          <div className={classes.quizInfo}>
            <Button
              variant="outlined"
              color="default"
              size="small"
              className={classes.buttonControl}
              onClick={quitTest}
            >
              Quit&nbsp; <ExitToAppIcon />
            </Button>
          </div>
          <Question
            currQues={currQues}
            setCurrQues={setCurrQues}
            question={question}
            setQuestion={setQuestion}
            totalTasks={totalTasks}
          />
        </>
      )}
    </div>
  );
};

export default Test;
