import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    height: 70,
    background: '#3B3E43',
    color: '#fff',
  },
  headerContainer: {
    maxWidth: 1100,
    width: '100%',
    margin: '0 auto',
  },
  logoLink: {
    textDecoration: 'none', 
    color: '#fff',
  },
  logoImage: {
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  logoText: {
    paddingLeft: 15,
    paddingTop: 5,
    fontWeight: 300,
  },
}));
