import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  questionName: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 21,
    },
  },
  options: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
  },
  singleOption: {
    width: '100%',
    fontSize: 21,
    padding: '15px 20px',
    marginBottom: '10px',
    border: 0,
    borderRadius: 5,
    backgroundColor: '#DCDCDC',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  codeBlock: {
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
  },
  select: {
    backgroundColor: 'rgb(63,81,181)',
    color: 'white',
    boxShadow: '0 0 1px black',
  },
}));
