import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  quiz: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px',
    maxWidth: '1600px',
    margin: '0 auto',
  },
  quizInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
    margin: '10px',
  },
  buttonControl: {
    width: 120,
    fontWeight: 600,
    width: 120,
    border: '2px solid #000',
  },
}));
