import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  questionName: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 21,
    },
  },
  itemsContainer: {
    display: 'flex',
    flex: 1,
  },
  sources: {
    width: '46%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
    // [theme.breakpoints.down('sm')]: {
    //   margin: '5px',
    // },
  },
  singleOption: {
    fontSize: 15,
    padding: '15px 20px',
    margin: '10px',
    border: 0,
    borderRadius: 5,
    backgroundColor: '#DCDCDC',
    width: '100%',
  },
  codeBlock: {
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
  },
  select: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 125,
    },
  },
  selectOption: {
    padding: 10,
    borderBottom: '1px solid #ccc',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}));
