import React, { useRef, useState } from 'react';
import API from '../../services/api.service';
import { useHistory } from 'react-router-dom';
import FormField from '../FormField';
import { useStyles } from './styles';

const Form = ({ goTo }) => {
  const loginRef = useRef();
  const passwordRef = useRef();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const history = useHistory();
  const classes = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const queryData = {
      login: loginRef.current.value,
      password: passwordRef.current.value,
    };

    try {
      const { data } = await API.login(queryData);
      data.login && history.push(goTo);
    } catch (e) {
      setIsLoggedIn(e?.data);
    }
  };

  return (
    <form className={classes.formStyle} onSubmit={handleSubmit}>
      <FormField ref={loginRef} label="Login" type="text" />
      <FormField ref={passwordRef} label="Password" type="password" />
      <div>
        <button className={classes.submitStyle} type="submit">
          Login
        </button>
        {isLoggedIn?.login === false && (
          <p className={classes.loginError}>Authorization data is incorrect!</p>
        )}
      </div>
    </form>
  );
};

export default Form;
