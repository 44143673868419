import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  questionCounter: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
    },
  },
  question: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  singleQuestion: {
    width: '95%',
    minHeight: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '20px',
  },
  controls: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
  },
  buttonControl: {
    width: 185,
    marginTop: 20,
    borderWidth: 0,
    backgroundColor: '#49639B',
    color: '#fff',
    border: '2px solid black',
    '&:hover': {
      backgroundColor: '#39A89E',
    },
  },
}));
