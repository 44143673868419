import React, { useState } from 'react';
import { useStyles } from './styles';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { useEffect } from 'react';

const MatchItems = ({ setSelected, question }) => {
  const classes = useStyles();
  const [answer, setAnswer] = useState([
    { source: 0, destination: 0 },
    { source: 0, destination: 1 },
    { source: 0, destination: 2 },
    { source: 0, destination: 3 },
  ]);

  const handleChange = (event, itemIndex) => {
    const localArr = [...answer];
    localArr.forEach((item) => {
      if (item.destination === itemIndex) {
        item.source = event.target.value;
      }
    });
    setAnswer(localArr);
    setSelected(localArr);
  };

  useEffect(() => {
    setSelected(answer);
  }, []);

  return (
    <>
      <h2 className={classes.questionName}>{question.question}</h2>
      <div className={classes.itemsContainer}>
        <div className={classes.sources}>
          {question.sources &&
            question.sources.sort((a, b) => a.id - b.id) &&
            question.sources.map((item, index) => (
              <FormControl
                variant="outlined"
                className={classes.singleOption}
                key={item.id}
              >
                <Select
                  value={answer[index].source}
                  onChange={(event) => handleChange(event, index)}
                  className={classes.select}
                >
                  {question.sources.map((it) => (
                    <MenuItem value={it.id} className={classes.selectOption}>{it.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
        </div>
        <div className={classes.sources}>
          {question.destinations &&
            question.destinations.sort((a, b) => a.id - b.id) &&
            question.destinations.map((item) => (
              <button className={`${classes.singleOption}`} key={item.id}>
                {item.label}
              </button>
            ))}
        </div>
      </div>
    </>
  );
};

export default MatchItems;
