import { useEffect, useState } from 'react';
import { CircularProgress, Button } from '@material-ui/core';
import { useStyles } from './styles';
import { useHistory } from 'react-router';
import API from '../../services/api.service';
import { minPercentageToPass } from '../../settings/applicationSettings';
import methods from '../../services/methods';
import Alert from '@material-ui/lab/Alert';

const Result = () => {
  const classes = useStyles();
  const [testResult, setTestResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();

  useEffect(async () => {
    try {
      setError('');
      setIsLoading(true);
      const { data } = await API.finishTest();
      setTestResult(data);
      setIsLoading(false);
    } catch (e) {
      setError(e.data.message);
      setIsLoading(false);
    }
  }, []);

  return (
    <div className={classes.result}>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading && (
        <CircularProgress
          style={{ margin: 100 }}
          color="inherit"
          size={50}
          thickness={1}
        />
      )}
      {!isLoading && testResult && (
        <>
          {testResult.percentage >= minPercentageToPass ? (
            <div style={{ textAlign: 'center' }}>
              <h1 className={classes.resultPassedHeader}>Test passed</h1>
              <svg
                id="Layer_1"
                enableBackground="new 0 0 512.063 512.063"
                height="75"
                viewBox="0 0 512.063 512.063"
                width="75"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <g>
                    <ellipse
                      cx="256.032"
                      cy="256.032"
                      fill="#00df76"
                      rx="255.949"
                      ry="256.032"
                    />
                  </g>
                  <path
                    d="m256.032 0c-.116 0-.231.004-.347.004v512.055c.116 0 .231.004.347.004 141.357 0 255.949-114.629 255.949-256.032s-114.592-256.031-255.949-256.031z"
                    fill="#00ab5e"
                  />
                  <path
                    d="m111.326 261.118 103.524 103.524c4.515 4.515 11.836 4.515 16.351 0l169.957-169.957c4.515-4.515 4.515-11.836 0-16.351l-30.935-30.935c-4.515-4.515-11.836-4.515-16.351 0l-123.617 123.615c-4.515 4.515-11.836 4.515-16.351 0l-55.397-55.397c-4.426-4.426-11.571-4.526-16.119-.226l-30.83 29.149c-4.732 4.475-4.837 11.973-.232 16.578z"
                    fill="#fff5f5"
                  />
                  <path
                    d="m370.223 147.398c-4.515-4.515-11.836-4.515-16.351 0l-98.187 98.187v94.573l145.473-145.473c4.515-4.515 4.515-11.836 0-16.352z"
                    fill="#dfebf1"
                  />
                </g>
              </svg>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <h1 className={classes.resultFailedHeader}>Test failed</h1>
              <svg
                height="75"
                viewBox="0 0 512.063 512.063"
                width="75"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#f44336">
                  <path d="m356.339844 296.347656-286.613282-286.613281c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503906-12.5 32.769532 0 45.25l286.613281 286.613282c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082032c12.523438-12.480468 12.523438-32.75.019532-45.25zm0 0" />
                  <path d="m295.988281 9.734375-286.613281 286.613281c-12.5 12.5-12.5 32.769532 0 45.25l15.082031 15.082032c12.503907 12.5 32.769531 12.5 45.25 0l286.632813-286.59375c12.503906-12.5 12.503906-32.765626 0-45.246094l-15.082032-15.082032c-12.5-12.523437-32.765624-12.523437-45.269531-.023437zm0 0" />
                </g>
              </svg>
            </div>
          )}
          <h3 className={classes.score}>
            Correct answers: {testResult.correct}/{testResult.total}
          </h3>
          <h3 className={classes.mark}>Your mark is: {testResult.percentage}%</h3>
          <div className={classes.topicsData}>
            <h4 className={classes.topicsToLearn}>Topics to learn:</h4>
            {testResult.recommendations &&
              testResult.recommendations.map((item) => (
                <div key={item.conceptId} className={classes.topicsItem}>
                  <a
                    href={'http://semantic-portal.net/' + item.domain}
                    target="_blank"
                    className={classes.topicsLink}
                  >
                    <h5 align="left" className={classes.topicsItemHeader}>
                      {item.title}
                    </h5>
                  </a>
                  <p>{item.description}</p>
                </div>
              ))}
          </div>
          <div className={classes.buttonsBlock}>
            <Button
              variant="contained"
              size="large"
              className={classes.buttonItem}
              style={{ backgroundColor: '#4A5B9A' }}
              onClick={() => methods.goHome()}
            >
              Go to homepage
            </Button>
            <Button
              variant="contained"
              size="large"
              className={classes.buttonItem}
              style={{ backgroundColor: '#36B39E' }}
              onClick={() => history.push('my-results')}
            >
              My results
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Result;
