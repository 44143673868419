import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    inputStyle: {
        margin: "5px 0 15px 0",
        padding: 10,
        border: 'none',
        borderRadius: "3px",
        boxSizing: "border-box",
        width: "100%",
        fontSize: 20,
    },
}));
