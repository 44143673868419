import { SET_TOKEN, SET_TOTAL_TASKS, SET_QUERY_PARAM } from "../types";

const initialState = {
    token: null,
    totalTasks: 5,
    queryParam: {
        name: 'branchId',
        value: '',
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_TOKEN:
            return { ...state, token: action.payload };
        case SET_TOTAL_TASKS: 
            return { ...state, totalTasks: action.payload };
        case SET_QUERY_PARAM: 
            return { ...state, queryParam: {...state.queryParam, value: action.payload }};
        default:
            return state;
    }
}
