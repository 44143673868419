import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  myResults: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 100,
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
    '& table': {
      borderCollapse: 'collapse',
      width: '100%',
    },
    '& td, th': {
      border: '1px solid #dddddd',
      textAlign: 'left',
      padding: 8,
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#dddddd',
    },
  },
}));
