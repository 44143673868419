import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh - 70px)',
    display: 'flex',
    background: '#4C4D99',
    backgroundImage: 'linear-gradient(143deg, #504099 0%, #32C39F 100%)',
  },
  errorMessage: {
    margin: 'auto',
    textAlign: "center",
    color: '#eee',
    textShadow: '4px 4px 5px rgba(0, 0, 0, .7)',
  },
}));
