import { shortTestValue } from '../settings/applicationSettings';

export default {
  getQueryParamName() {
    return window.location.search.split('=').shift().substring(1);
  },
  getQueryParamShort() {
    return window.location.search.split('&').find(item => item === shortTestValue);
  },
  getQueryParamValue(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  },

  async goHome() {
    window.open('http://semantic-portal.net/my:home', '_self');
    return null;
  },
  toISOLocal(d) {
    var z = (n) => ('0' + n).slice(-2);
    var zz = (n) => ('00' + n).slice(-3);
    var off = d.getTimezoneOffset();
    var sign = off < 0 ? '+' : '-';
    off = Math.abs(off);

    return (
      d.getFullYear() +
      '-' +
      z(d.getMonth() + 1) +
      '-' +
      z(d.getDate()) +
      'T' +
      z(d.getHours()) +
      ':' +
      z(d.getMinutes()) +
      ':' +
      z(d.getSeconds()) +
      '.' +
      zz(d.getMilliseconds()) +
      sign +
      z((off / 60) | 0) +
      ':' +
      z(off % 60)
    );
  },
};
