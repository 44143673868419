import React from 'react';
import { useStyles } from './styles';
import logo from '../../assets/img/semanticLogo.png';

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.headerContainer}>
        <a
          href="http://semantic-portal.net"
          target="_blank"
          rel="noreferrer"
          className={classes.logoLink}
        >
          <div className={classes.row}>
            <img src={logo} alt="Logo" className={classes.logoImage} />
            <h3 className={classes.logoText}>Semantic portal</h3>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Header;
